import * as React from "react";
import type { HeadFC } from "gatsby";

import { Layout } from "../components/Layout";
import { Banner } from "../containers/trading-ideas/banner";
import { Discover } from "../containers/trading-ideas/discover";
import { CTA } from "../containers/trading-ideas/cta";

const TradingIdeaPage = () => {
  return (
    <Layout tradeIdea={true}>
      <Banner />
      <Discover />
      <CTA />
    </Layout>
  );
};

export default TradingIdeaPage;

export const Head: HeadFC = () => <title>Trading Idea</title>;
