import React from "react";
import styled from "styled-components";

import { Container } from "../../components/Grid";
import { ScButton } from "./button";

const ScMain = styled.div`
  text-align: center;
`;

const ScSection = styled.div`
  margin-top: 6rem;
  margin-bottom: 6rem;

  img {
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 24px;
  }

  @media screen and (min-width: 992px) {
    h3 {
      font-size: 36px;
    }
  }
`;

export const CTA = () => {
  return (
    <ScMain>
      <Container>
        <ScSection>
          <img src="/images/trading-ideas/ti-footer.png" alt="" />

          <h3>BE THE FIRST TO EXPERIENCE POWER&nbsp;IDEAS</h3>

          <p>Join the waitlist to experience Power Ideas for yourself.</p>

          <ScButton
            href="https://forms.gle/BQJfuYtayBv7jD9P6"
            target="_blank"
            rel="noopener noreferrer"
          >
            Join the waitlist
          </ScButton>
        </ScSection>
      </Container>
    </ScMain>
  );
};
