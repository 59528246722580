import styled from "styled-components";

export const ScButton = styled.a`
  background: #00baba;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  display: inline-block;
  height: 38px;
  line-height: 38px;
  font-size: 16px;
  text-align: center;
  color: #000000;
  font-weight: bold;
  padding-left: 24px;
  padding-right: 24px;
  font-family: "Archivo";
  transition: all 0.3s;

  &:hover {
    background: #009b9b;
    text-decoration: none;
    color: #000000;
  }
`;
