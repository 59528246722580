import React from "react";
import styled from "styled-components";

import { Container } from "../../components/Grid";

const ScMain = styled.div``;

const ScSection = styled.div`
  margin-top: 6rem;
  margin-bottom: 6rem;
`;

const ScHeader = styled.div`
  position: relative;
  margin-bottom: 72px;

  h2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 39px;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 36px;
  }

  @media screen and (min-width: 992px) {
    font-size: 36px;
    line-height: 40px;
  }
`;

const ScImgBulb = styled.img`
  position: absolute;
  width: 120px;
  right: -2rem;
  top: -6rem;

  @media screen and (min-width: 992px) {
    width: 140px;
    right: 4rem;
    top: -4rem;
  }
`;

const ScBody = styled.div`
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    text-transform: uppercase;
    color: #ffffff;
    max-width: 780px;
    margin-bottom: 36px;
  }
`;

const ScList = styled.div`
  @media screen and (min-width: 992px) {
    margin-left: -12px;
    margin-right: -12px;
    display: flex;
  }
`;

const ScItem = styled.div`
  background: #0e1013;
  border-radius: 8px;
  font-size: 16px;
  flex: 1;
  margin-bottom: 1rem;
  padding: 16px 24px;

  h3 {
    font-size: 18px;
    line-height: 20px;
    color: #ffffff;
    font-weight: bold;
  }

  svg {
    margin-bottom: 16px;
  }

  @media screen and (min-width: 992px) {
    margin-left: 12px;
    margin-right: 12px;
    padding: 24px 38px;
  }
`;

export const Discover = () => {
  return (
    <ScMain>
      <ScSection>
        <Container>
          <ScHeader>
            <h2>Discover IDeas. Trade them.</h2>
            <ScImgBulb src="/images/trading-ideas/ti-bulb.png" />
            <img
              src="/images/trading-ideas/ti-chart.png"
              style={{ width: "100%" }}
            />
          </ScHeader>

          <ScBody>
            <h2>
              The first RFQ Builder that allows you to compose trade ideas
            </h2>

            <ScList>
              <ScItem>
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.82609 13.5652H0.565217C0.253217 13.5652 0 13.3126 0 13C0 12.6875 0.253217 12.4348 0.565217 12.4348H2.82609C3.13809 12.4348 3.3913 12.6875 3.3913 13C3.3913 13.3126 3.13809 13.5652 2.82609 13.5652Z"
                    fill="#00BABA"
                  />
                  <path
                    d="M5.80594 6.37112C5.66125 6.37112 5.51655 6.31573 5.40634 6.20551L3.8079 4.60651C3.5869 4.38551 3.5869 4.02829 3.8079 3.80729C4.0289 3.58629 4.38612 3.58629 4.60712 3.80729L6.20555 5.40629C6.42655 5.62729 6.42655 5.98451 6.20555 6.20551C6.09534 6.31629 5.95064 6.37112 5.80594 6.37112Z"
                    fill="#00BABA"
                  />
                  <path
                    d="M12.9999 3.3913C12.6879 3.3913 12.4347 3.13865 12.4347 2.82609V0.565217C12.4347 0.252652 12.6879 0 12.9999 0C13.3119 0 13.5651 0.252652 13.5651 0.565217V2.82609C13.5651 3.13865 13.3119 3.3913 12.9999 3.3913Z"
                    fill="#00BABA"
                  />
                  <path
                    d="M20.1941 6.37112C20.0494 6.37112 19.9047 6.31573 19.7945 6.20551C19.5735 5.98451 19.5735 5.62729 19.7945 5.40629L21.3929 3.80729C21.6133 3.58629 21.9711 3.58629 22.1921 3.80729C22.4131 4.02829 22.4131 4.38551 22.1921 4.60651L20.5937 6.20551C20.4835 6.31629 20.3388 6.37112 20.1941 6.37112Z"
                    fill="#00BABA"
                  />
                  <path
                    d="M25.4349 13.5652H23.174C22.862 13.5652 22.6088 13.3126 22.6088 13C22.6088 12.6875 22.862 12.4348 23.174 12.4348H25.4349C25.7469 12.4348 26.0001 12.6875 26.0001 13C26.0001 13.3126 25.7469 13.5652 25.4349 13.5652Z"
                    fill="#00BABA"
                  />
                  <path
                    d="M13 5.65216C8.94857 5.65216 5.65222 8.94851 5.65222 13C5.65222 15.9674 7.45357 18.6409 10.174 19.7758V23.1739H15.8261V19.7758C18.5465 18.6409 20.3479 15.9674 20.3479 13C20.3479 8.94851 17.0515 5.65216 13 5.65216Z"
                    fill="#00BABA"
                  />
                  <path
                    d="M11.8695 26H14.1304C15.067 26 15.8261 25.2409 15.8261 24.3043H10.1739C10.1739 25.2409 10.933 26 11.8695 26Z"
                    fill="#00BABA"
                  />
                </svg>

                <h3>Generate trade ideas</h3>
                <p>
                  Create a structure in the RFQ builder that forms the basis of
                  the idea, compose the details.
                </p>
              </ScItem>
              <ScItem>
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1_110)">
                    <path
                      d="M24.9494 5.92556L20.0744 1.05056C19.9221 0.898179 19.7155 0.812546 19.5 0.8125H6.5C6.28453 0.812546 6.0779 0.898179 5.92556 1.05056L1.05056 5.92556C0.898179 6.0779 0.812546 6.28453 0.8125 6.5V21.9375C0.8125 22.7995 1.15491 23.6261 1.7644 24.2356C2.3739 24.8451 3.20055 25.1875 4.0625 25.1875H21.9375C22.7995 25.1875 23.6261 24.8451 24.2356 24.2356C24.8451 23.6261 25.1875 22.7995 25.1875 21.9375V6.5C25.1875 6.28453 25.1018 6.0779 24.9494 5.92556V5.92556ZM13 21.125L8.125 15.4375H11.375V9.75H14.625V15.4375H17.875L13 21.125ZM3.58638 5.6875L6.83637 2.4375H19.1636L22.4136 5.6875H3.58638Z"
                      fill="#00BABA"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_110">
                      <rect width="26" height="26" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <h3>Save ideas, trade later</h3>
                <p>Save your trade ideas, and come back later to trade them.</p>
              </ScItem>
              <ScItem>
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1_113)">
                    <path
                      d="M13 0.541687C6.13005 0.541687 0.541672 6.1306 0.541672 13C0.541672 19.8694 6.13005 25.4584 13 25.4584C19.87 25.4584 25.4583 19.8694 25.4583 13C25.4583 6.1306 19.87 0.541687 13 0.541687ZM19.6853 11.8842L13.9642 13.9642L11.8836 19.6853C11.8062 19.8998 11.602 20.0417 11.375 20.0417C11.3685 20.0417 11.3625 20.0417 11.356 20.0417C11.121 20.033 10.9184 19.8749 10.8539 19.649L7.60392 8.27398C7.54976 8.0844 7.60284 7.88127 7.74205 7.74206C7.8818 7.60285 8.08601 7.55031 8.27342 7.60448L19.6484 10.8545C19.8743 10.9189 20.033 11.1215 20.0411 11.3561C20.0498 11.5906 19.9057 11.804 19.6853 11.8842Z"
                      fill="#00BABA"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_113">
                      <rect width="26" height="26" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <h3>Discover ideas</h3>
                <p>
                  Browse public trade ideas and discover ideas that fit your
                  trading strategy.
                </p>
              </ScItem>
            </ScList>
          </ScBody>
        </Container>
      </ScSection>
    </ScMain>
  );
};
